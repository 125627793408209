import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Carousel, Nav, Tab, Form } from "react-bootstrap";
import "./Sale.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./ProductSlider/ProdcutSlider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { Navigation } from "swiper/modules";
import { Context } from "../../../utils/context";
import {
  deletSales,
  getAllProducts,
  getAllPureProducts,
  getAllSales,
} from "../../../utils/apis/Product/Product.js";
import {
  getWishlist,
  addWishlist,
} from "../../../utils/apis/Wishlist/Wishlist.js";
import { AddToCart } from "../../../utils/apis/addToCart/addToCart.js";
import Heart_Component from "../../Common-Component/Heart_Component/Heart_Component";
import SupermarketModal from "../../Common-Component/Supermarket-modal/SupermarketModal";
import { Link, useNavigate } from "react-router-dom";
const Sale = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const navigate = useNavigate();

  const [swiperInstanceOne, setSwiperInstanceOne] = useState(null);
  const [swiperInstanceTwo, setSwiperInstanceTwo] = useState(null);
  const [swiperInstanceThree, setSwiperInstanceThree] = useState(null);

  const handleMainSliderClickone = (direction) => {
    if (swiperInstanceOne && swiperInstanceTwo && swiperInstanceThree) {
      if (direction === "prev") {
        swiperInstanceOne.slidePrev();
        swiperInstanceTwo.slidePrev();
        swiperInstanceThree.slidePrev();
      } else if (direction === "next") {
        swiperInstanceOne.slideNext();
        swiperInstanceTwo.slideNext();
        swiperInstanceThree.slideNext();
      }
    }
  };

  const [showButtons, setShowButtons] = useState(false);
  const [show, setShow] = useState(false);

  const { IMG_URL, triggerCartUpdate, setAddCartModal, addCartModal, signin } =
    useContext(Context);

  const [products, setProducts] = useState([]);

  const getAllProduct = async () => {
    const res = await getAllProducts();
    if (res?.success) {
      setProducts(res?.data);
    }
  };
  const [pureProducts, setPureProducts] = useState([]);
  const getAllPureProduct = async () => {
    const res = await getAllPureProducts();
    if (res?.success) {
      setPureProducts(res?.data);
    }
  };

  const [sales, setSales] = useState([]);

  const getAllSale = async () => {
    const res = await getAllSales();
    if (res?.success) {
      setSales(res?.data);
    }
  };

  useEffect(() => {
    getAllProduct();
    getAllSale();
    getAllPureProduct();
  }, []);

  const addToWishlist = async (product_id) => {
    const res = await addWishlist(product_id);
    if (res?.success) {
      getAllProduct();
    }
  };

  const addToCart = async (product_id) => {
    const data = {
      product_id: product_id,
    };
    const res = await AddToCart(data);
    if (res?.success) {
      setShow(!show);
      getAllProduct();
      triggerCartUpdate();
    }
    setShow(!show);
  };

  const calculateOffPercentage = (mrp, S_Price) => {
    if (mrp > 0) {
      return ((mrp - S_Price) / mrp) * 100;
    }
    return 0;
  };

  const CountdownTimer = ({ endDate, product_id }) => {
    const calculateTimeLeft = () => {
      const difference = +new Date(endDate) - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        deletSales(product_id.id);
        timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearInterval(timer);
    }, [endDate]); // Add endDate as a dependency to recalculate time left when it changes

    return (
      <div className="d-flex number-align">
        <div className="space m-2">
          <p className="number">{timeLeft.days}</p>
          <p className="name">Days</p>
        </div>
        <div className="space m-2">
          <p className="number">{timeLeft.hours}</p>
          <p className="name">Hours</p>
        </div>
        <div className="space m-2">
          <p className="number">{timeLeft.minutes}</p>
          <p className="name">Minutes</p>
        </div>
        <div className="space m-2">
          <p className="number">{timeLeft.seconds}</p>
          <p className="name">Seconds</p>
        </div>
      </div>
    );
  };

  return (
    <section className="tab-products mt-5">
      <Container>
        <Row>
          <Col lg={3} md={12} sm={12} xs={12} className="space">
            <div className="first-section">
              <div
                className="main-slider mobile_slider"
                onClick={() => handleMainSliderClick("prev")}
              >
                <div className="main-arrow-back">
                  <div className="arrow-back arrowmobile" />
                </div>
              </div>
              <div
                className="main-slider"
                onClick={() => handleMainSliderClick("next")}
              >
                <div className="main-arrow-next">
                  <div className="arrow-next arrowmobile" />
                </div>
              </div>
              <div className="tabs-bd p-3">
                <h5>Sale</h5>
              </div>

              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                autoplay={{ delay: 3000 }}
                className="sale-slider"
                onSwiper={(swiper) => setSwiperInstance(swiper)}
              >
                {sales.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="content p-2">
                      <div
                        className="img-align"
                        onClick={() =>
                          navigate(`product-details/${item?.product?.id}`)
                        }
                      >
                        <img
                          className="img-fluid img-set"
                          src={IMG_URL + item?.product?.image1}
                          alt=""
                        />
                      </div>

                      <div className="text-center">
                        <CountdownTimer
                          endDate={item?.e_date}
                          product_id={item}
                        />
                      </div>

                      <div className="d-flex flex-wrap ">
                        <div>
                          <h5 className="pro-name">{item?.product?.name}</h5>
                          <p className="product-cont">{item?.description}</p>
                        </div>

                        <div className="price-align">
                          <p className="price">
                            ₹.{item?.sale_price}
                            <span className="price-span">
                              ₹.{item?.product?.mrp}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="no-sale-product">
                <h1>no sale product is available</h1>
              </div>
            </div>
          </Col>
          <Col lg={9} md={12} sm={12} xs={12}>
            <div className="second-section">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="second-buttons">
                  <div
                    className="main-slider mobile_slider"
                    onClick={() => handleMainSliderClickone("prev")}
                  >
                    <div className="main-arrow-back">
                      <div className="arrow-back arrowmobile" />
                    </div>
                  </div>
                  <div
                    className="main-slider"
                    onClick={() => handleMainSliderClickone("next")}
                  >
                    <div className="main-arrow-next">
                      <div className="arrow-next arrowmobile" />
                    </div>
                  </div>
                </div>
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Featured</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Latest</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Bestseller</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Swiper
                      spaceBetween={10}
                      pagination={{ clickable: true }}
                      autoplay={{ delay: 3000 }}
                      className="featured-slider mt-5"
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        380: { slidesPerView: 1 },
                        485: { slidesPerView: 2 },
                        575: { slidesPerView: 2 },
                        768: { slidesPerView: 3 },
                        992: { slidesPerView: 3 },
                        1024: { slidesPerView: 3 },
                        1200: { slidesPerView: 4 },
                        1440: { slidesPerView: 4 },
                        2000: { slidesPerView: 4 },
                      }}
                      onSwiper={(swiper) => setSwiperInstanceOne(swiper)}
                    >
                      {products
                        .filter(
                          (product) => product.product_type?.type === "featured"
                        )
                        .map((product) => (
                          <SwiperSlide key={product.id}>
                            <div className="card-section">
                              <div className="card">
                                <div className="card-set">
                                  <Row>
                                    <Col xs={6}>
                                      <p className="offer-card-p">
                                        {calculateOffPercentage(
                                          product?.mrp,
                                          product?.s_price
                                        ).toFixed(2)}
                                        %
                                      </p>
                                    </Col>
                                    <Col xs={6}>
                                      <div className="wishlist-div">
                                        <Heart_Component
                                          product={product}
                                          // variant={selectedVariant}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Link
                                    to={`/product-details/${product?.id}`}
                                    className="details-link"
                                  >
                                    <div className="card-body">
                                      <img
                                        src={IMG_URL + product?.image1}
                                        className="product-img"
                                        alt="Product"
                                      />
                                    </div>
                                  </Link>
                                </div>
                                <div className="details">
                                  <Row>
                                    <Col xxl={12} lg={12} md={12} xs={12}>
                                      <Link
                                        to={`/product-details/${product?.id}`}
                                        className="details-link"
                                      >
                                        <p className="product-name">
                                          {product.name}
                                        </p>
                                      </Link>
                                    </Col>
                                    <Col xxl={12} lg={12} md={12} xs={12}>
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                          <p className="product-price">
                                            ₹ {product.s_price}
                                          </p>
                                          <p className="product-price-disabled">
                                            <span className="disablesub">
                                              ₹ {product.mrp}
                                            </span>
                                            <span className="offerprice">
                                              {product.Offerprice}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="">
                                          <div
                                            id="first-div"
                                            className="shopping-img-align onclickcart"
                                          >
                                            {signin ? (
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Image/Icon/shopping-cart3.png"
                                                }
                                                className="shopping-cart-img"
                                                alt="Shopping Cart"
                                                // onClick={() => setShow(!show)}
                                                onClick={() => {
                                                  addToCart(product?.id);
                                                  setAddCartModal(true);
                                                  triggerCartUpdate();
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/Image/Icon/shopping-cart3.png"
                                                }
                                                className="shopping-cart-img"
                                                alt="Shopping Cart"
                                                onClick={() => {
                                                  addToCart(product?.id);
                                                  setAddCartModal(true);
                                                  triggerCartUpdate();
                                                }}
                                              />
                                            )}
                                          </div>
                                          {/* {show && (
                                        <div id="second-div" className="shopping-img-align">
                                          <div className="d-flex add-cart-box">
                                            <button className="minus btn" onClick={() => handleMinusClick()}>-</button>
                                            <div className="quantity">{number}</div>
                                            <button className="add btn" onClick={() => handlePlusClick()}>+</button>
                                          </div>
                                        </div>
                                      )} */}
                                          <br />

                                          {/* <div className="d-flex mt-1 shopping-align multicolors">
                                        <Form.Check
                                          inline
                                          name="group1"
                                          type="radio"
                                          className="bt1"

                                        />
                                        <Form.Check
                                          inline
                                          name="group1"
                                          type="radio"
                                          className="bt2"

                                        />
                                        <Form.Check
                                          inline
                                          name="group1"
                                          type="radio"
                                          className="bt3"

                                        />
                                        <Form.Check
                                          inline
                                          name="group1"
                                          type="radio"
                                          className="bt4"

                                        />
                                        <Form.Check
                                          inline
                                          name="group1"
                                          type="radio"
                                          className="bt5"

                                        />

                                      </div> */}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Swiper
                      spaceBetween={10}
                      pagination={{ clickable: true }}
                      autoplay={{ delay: 3000 }}
                      className="featured-slider mt-5"
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        380: { slidesPerView: 1 },
                        485: { slidesPerView: 2 },
                        575: { slidesPerView: 2 },
                        768: { slidesPerView: 3 },
                        992: { slidesPerView: 3 },
                        1024: { slidesPerView: 3 },
                        1200: { slidesPerView: 4 },
                        1440: { slidesPerView: 4 },
                        2000: { slidesPerView: 4 },
                      }}
                      onSwiper={(swiper) => setSwiperInstanceTwo(swiper)}
                    >
                      {pureProducts.map((product) => (
                        <SwiperSlide key={product.id}>
                          <div className="card-section">
                            <div className="card">
                              <div className="card-set">
                                <Row>
                                  <Col xs={6}>
                                    <p className="offer-card-p">
                                      {calculateOffPercentage(
                                        product?.mrp,
                                        product?.s_price
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  </Col>
                                  <Col xs={6}>
                                    <div className="wishlist-div">
                                      {/* <FontAwesomeIcon
                                        style={{ color: isHeartRed[product.abc] ? 'red' : '#535353' }}
                                        icon={faHeart} className="heart-iconss"
                                        onClick={() => addToWishlist(product.id)}
                                      /> */}
                                      <Heart_Component
                                        product={product}
                                        // variant={selectedVariant}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Link
                                  to={`/product-details/${product?.id}`}
                                  className="details-link"
                                >
                                  <div className="card-body">
                                    <img
                                      src={IMG_URL + product?.image1}
                                      className="product-img"
                                      alt="Product"
                                    />
                                  </div>
                                </Link>
                              </div>
                              <div className="details">
                                <Row>
                                  <Col xxl={12} lg={12} md={12} xs={12}>
                                    <p className="product-name">
                                      {product.name}
                                    </p>
                                  </Col>
                                  <Col xxl={12} lg={12} md={12} xs={12}>
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex">
                                        <p className="product-price">
                                          ₹ {product.s_price}
                                        </p>

                                        <p className="product-price-disabled">
                                          <span className="disablesub">
                                            ₹ {product.mrp}
                                          </span>
                                          <span className="offerprice">
                                            {product.Offerprice}
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        id="first-div"
                                        className="shopping-img-align onclickcart"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Image/Icon/shopping-cart3.png"
                                          }
                                          className="shopping-cart-img"
                                          alt="Shopping Cart"
                                          onClick={() => {
                                            addToCart(product?.id);
                                            setAddCartModal(true);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Swiper
                      spaceBetween={10}
                      pagination={{ clickable: true }}
                      autoplay={{ delay: 3000 }}
                      className="featured-slider mt-5"
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        380: { slidesPerView: 1 },
                        485: { slidesPerView: 2 },
                        575: { slidesPerView: 2 },
                        768: { slidesPerView: 3 },
                        992: { slidesPerView: 3 },
                        1024: { slidesPerView: 3 },
                        1200: { slidesPerView: 4 },
                        1440: { slidesPerView: 4 },
                        2000: { slidesPerView: 4 },
                      }}
                      navigation={true}
                      modules={[Navigation]}
                      onSwiper={(swiper) => setSwiperInstanceThree(swiper)}
                    >
                      {products
                        .filter(
                          (product) =>
                            product.product_type?.type === "bestseller"
                        )
                        .map((product) => (
                          <SwiperSlide key={product.id}>
                            <div className="card-section">
                              <div className="card">
                                <div className="card-set">
                                  <Row>
                                    <Col xs={6}>
                                      <p className="offer-card-p">
                                        {calculateOffPercentage(
                                          product?.mrp,
                                          product?.s_price
                                        ).toFixed(2)}
                                        %
                                      </p>
                                    </Col>
                                    <Col xs={6}>
                                      <div className="wishlist-div">
                                        {/* <FontAwesomeIcon
                                        style={{ color: isHeartRed[product.abc] ? 'red' : '#535353' }}
                                        icon={faHeart} className="heart-iconss"
                                        onClick={() => addToWishlist(product.id)}
                                      /> */}
                                        <Heart_Component
                                          product={product}
                                          // variant={selectedVariant}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Link
                                    to={`/product-details/${product?.id}`}
                                    className="details-link"
                                  >
                                    <div className="card-body">
                                      <img
                                        src={IMG_URL + product?.image1}
                                        className="product-img"
                                        alt="Product"
                                      />
                                    </div>
                                  </Link>
                                </div>
                                <div className="details">
                                  <Row>
                                    <Col xxl={12} lg={12} md={12} xs={12}>
                                      {/* <Link to={`/product-details/${product?.id}`} className="details-link"> */}
                                      <p className="product-name">
                                        {product.name}
                                      </p>

                                      {/* </Link> */}
                                    </Col>
                                    <Col xxl={12} lg={12} md={12} xs={12}>
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                          <p className="product-price">
                                            ₹ {product.s_price}
                                          </p>

                                          <p className="product-price-disabled">
                                            <span className="disablesub">
                                              ₹ {product.mrp}
                                            </span>
                                            <span className="offerprice">
                                              {product.Offerprice}
                                            </span>
                                          </p>
                                        </div>

                                        <div
                                          id="first-div"
                                          className="shopping-img-align onclickcart"
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/Image/Icon/shopping-cart3.png"
                                            }
                                            className="shopping-cart-img"
                                            alt="Shopping Cart"
                                            // onClick={() => setShow(!show)}
                                            onClick={() => {
                                              addToCart(product?.id);
                                              setAddCartModal(true);
                                              triggerCartUpdate();
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <SupermarketModal
        show={addCartModal}
        onHide={() => setAddCartModal(false)}
      />
    </section>
  );
};

export default Sale;
