import React, { useState, useEffect, useContext } from "react";
import "./History.css";
import { Breadcrumb, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Purchased_Items_Modal from "../../../Common-Component/Purchased_Items_Modal/Purchased_Items_Modal";
import PurchasedItemsReturnModal from "../../../AllModal/Purchased_Items_Modal/PurchasedItemReturnModal";
import { Context } from "../../../../utils/context";
import { orderGet } from "../../../../utils/apis/myAccount/myAccount";
import { useNavigate } from "react-router-dom";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
function History() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowOne, setModalShowOne] = React.useState(false);

  const navigate = useNavigate();
  const { IMG_URL } = useContext(Context);

  const [data, setData] = useState();

  const [orders, setOrder] = useState([]);
  const getOrders = async () => {
    const res = await orderGet();
    if (res?.success) {
      setOrder(res?.data);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <>
      <section className="my-account checkout-inner-steps ">
        <div className="container">
          <div className="account-table confirm_order Product_Cart">
            <div className="heading_holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={"/my-account"}>My account</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link to={"/order-history"}>Order history</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="back-text-holder">
              <Link to={"/my-account"}>
                <p>Back</p>
              </Link>
            </div>

            <div className="product_table">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Order No.</th>
                    <th>Products Name</th>
                    <th>Order Date</th>
                    <th>Expected Delivery Date</th>
                    <th>Total</th>
                    <th>Quantity</th>
                    <th>Payment Type</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Order Status Date</th>
                    <th>Order Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.length > 0
                    ? orders?.map((value, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{value?.invoice_number}</td>{" "}
                          <td>
                            {value?.orders_details.map((orderDetail, index) => (
                              <span key={index}>
                                {orderDetail.product?.name}
                              </span>
                            ))}
                          </td>
                          <td>{(value?.createdAt).split("T")[0]}</td>
                          <td>
                            {(function () {
                              if (!value?.createdAt) return "";
                              const date = new Date(value.createdAt);
                              date.setDate(date.getDate() + 3);
                              return date.toISOString().split("T")[0];
                            })()}
                          </td>
                          <td>₹ {value?.amount}</td>
                          <td>{value?.orders_details?.length}</td>
                          <td>{value?.payment_mode}</td>
                        
                          <td
                            className={
                              value?.payment_status?.status === 1
                                ? "green-text"
                                : "red-text"
                            }
                          >
                            {value?.payment_status?.status === 1
                              ? "Paid"
                              : "UnPaid"}
                          </td>
                          <td className="green-text">
                            {value?.order_status?.order_status}
                          </td>
                          <td>
                            {/* {(function () {
                              if (!value?.updatedAt) return "";
                              const date = new Date(value.updatedAt);
                              date.setDate(date.getDate() + 3);
                              return date.toISOString().split("T")[0];
                            })()} */}
                           
                             {value?.updatedAt ? new Date(value.updatedAt).toLocaleDateString('en-US', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                    }) : ''}
                          </td>
                          <td
                            className="view-text"
                            onClick={() => {
                              setData(value);
                              setModalShow(true);
                            }}
                          >
                            View
                          </td>
                        </tr>
                      ))
                    : "No Order Found"}
                  {/* {orders?.map((value, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{value?.invoice_number}</td>
                      <td>{(value?.createdAt).split("T")[0]}</td>
                      <td>
                        {(function () {
                          if (!value?.createdAt) return "";
                          const date = new Date(value.createdAt);
                          date.setDate(date.getDate() + 3);
                          return date.toISOString().split("T")[0];
                        })()}
                      </td>

                      <td>₹ {value?.amount}</td>
                      <td>{value?.payment_mode}</td>

                      <td
                        className={
                          value?.payment_status?.status === 1
                            ? "green-text"
                            : "red-text"
                        }
                      >
                        {value?.payment_status?.status === 1
                          ? "Paid"
                          : "UnPaid"}
                      </td>
                      <td className="green-text">
                        {value?.order_status?.order_status}
                      </td>

                      <td>
                        {(function () {
                          if (!value?.updatedAt) return "";
                          const date = new Date(value.updatedAt);
                          date.setDate(date.getDate() + 3);
                          return date.toISOString().split("T")[0];
                        })()}
                      </td>

                      <td
                        className="view-text"
                        onClick={() => {
                          setData(value);
                          setModalShow(true);
                        }}
                      >
                        View
                      </td>
                    </tr>
                  ))} */}

                  {/* <tr>
                    <td>2</td>
                    <td>#567865</td>
                    <td>19-09-21</td>
                    <td>21-09-21</td>

                    <td>₹500</td>
                    <td>-</td>
                    <td>Online</td>
                    <td className="red-text">Unpaid</td>
                    <td className="yellow-text">Ordered</td>
                    <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                  </tr> */}
                </tbody>
              </Table>

              <Common_Button
                onClick={() => navigate("/order-return-history")}
                className="SubmitBtn"
                text={"View Return History"}
              />

              <div className="span-holder">
                {/* <span>Showing 3 of 3 entries</span> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Purchased_Items_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
      <PurchasedItemsReturnModal
        data={data}
        show={modalShowOne}
        onHide={() => setModalShowOne(false)}
      />
    </>
  );
}

export default History;
