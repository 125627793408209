import React, { useEffect, useState } from "react";
import "./CheckoutProcess.css";
import OneAddress from "../CheckoutProcess/OneAdress/OneAdress";
import TwoDelivery from "./TwoDelivery/TwoDelivery";
import ThreePayment from "./ThreePayment/ThreePayment";
import FourConform from "./FourConform/FourConform";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
const CheckoutProcess = () => {
  const [current, setCurrent] = useState(1);
  const [addressId, setAddressId] = useState("");
  const [addressData, setAddressData] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cod");

  const stepLabels = [
    "1. Address Details ",
    "2. Delivery Options",
    "3. Payment Options",
    "4. Confirm Order",
  ]; // Define custom labels for each step

  const handleNext = () => {
    if (current < 4) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <section className="checkout-process-step-form">
        <div className="container">
          <Form>
            <Breadcrumb className="my-4">
              <Breadcrumb.Item href="/">
                <FontAwesomeIcon icon="fa-solid fa-house" />{" "}
                <FontAwesomeIcon
                  icon="fa-solid fa-angle-right"
                  className="link-bredcumb"
                />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Cart </Breadcrumb.Item>
            </Breadcrumb>
            <div className="progress-bar-container">
              <div className="step-row">
                {stepLabels.map((label, index) => (
                  <div
                    key={index + 1}
                    className={`step-container ${
                      index + 1 === current ? "active" : ""
                    }`}
                  >
                    <div>
                      <span
                        className={`step-text ${
                          isStepCompleted(index + 1) ? "completed" : ""
                        } ${index + 1 === current ? "active" : ""}`}
                      >
                        {label}
                      </span>
                    </div>
                    <div className="circle-center">
                      <div
                        className={`circle ${
                          isStepCompleted(index + 1) ? "completed" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="steps mt-3">
              {current === 1 && (
                <OneAddress
                  handleNext={handleNext}
                  addressId={addressId}
                  setAddressId={setAddressId}
                  addressData={addressData}
                  setAddressData={setAddressData}
                />
              )}
              {current === 2 && (
                <TwoDelivery
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              )}
              {current === 3 && (
                <ThreePayment
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              )}
              {current === 4 && (
                <FourConform
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  paymentMethod={paymentMethod}
                  addressId={addressId}
                />
              )}
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default CheckoutProcess;
