import React, { useState, useEffect, useCallback, useContext } from "react";
import { Col, Form, Row, Nav } from "react-bootstrap";
import "./ProductFeature.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import ProductSuccessful from "../../AllModal/ProductSuccessful";
import { Context } from "../../../utils/context";
import {
  faCartShopping,
  faHeart as solidHeart,
} from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Heart_Component from "../../Common-Component/Heart_Component/Heart_Component";
import { getCartCheck } from "../../../utils/apis/addToCart/addToCart";
import { useForm } from "react-hook-form";
import { getProductVariant } from "../../../utils/apis/Product/Product";

import {
  AddToCart,
  CartQuantity,
} from "../../../utils/apis/addToCart/addToCart";
const ProcductFeature = ({
  product,
  cartStatus,
  cartData,
  getProduct,
  setCartStatus,
  setVariantId,
  setSelected,
  setCartData,
}) => {
  const [modalShow, setModalShow] = useState(false);

  const [isHeartRed, setIsHeartRed] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleHeartColor = (index) => {
    const newHeartColors = [...isHeartRed];
    newHeartColors[index] = !newHeartColors[index];
    setIsHeartRed(newHeartColors);
  };
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  // const [number, setNumber] = useState(1);
  // const handleMinusClick = () => {
  //   if (number > 1) {
  //     setNumber(number - 1);
  //   }
  // };

  // const handlePlusClick = () => {
  //   setNumber(number + 1);
  // };

  const imageSources = [
    "/assets/Image/Product-details/pen1-png.png",
    "/assets/Image/Product-details/pen2-png.png",
    "/assets/Image/Product-details/pen3-png.png",
    "/assets/Image/Product-details/pen1-png.png",
    "/assets/Image/Product-details/pen2-png.png",
  ];

  const {
    variant,
    setVariant,
    IMG_URL,
    triggerCartUpdate,
    changeQuantity,
    setChangeQuantity,
  } = useContext(Context);
  const [number, setNumber] = useState(1);
  const [showButtons, setShowButtons] = useState(false);
  const [showHeart, setShowHeart] = useState(false); // State for heart icon
  const [prdId, setPrdId] = useState("");
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [dataCart, setDataCart] = useState({});

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  const handleHeartClick = () => {
    setShowHeart(!showHeart); // Toggle the heart icon state
    setShow(false); // Hide the section with blue background and buttons
  };
  const [show, setShow] = useState(false);

  const addCart = async (data) => {
    const res = await AddToCart(data);

    // await getProduct();
    // const data = { product_id: product?.id, variant_id: selectedVariant?.id };
    const response = await getCartCheck(data);
    if (response?.success) {
      setCartStatus(true);
      setCartData(response?.cartItem);
    } else {
      setCartStatus(false);
      setCartData(null);
    }
  };

  const getCartQuantity = async (id, type, quantity) => {
    await CartQuantity(id, type, selectedVariant?.id);
    if (quantity == 1 && type === "minus") {
      setCartStatus(false);
    }
    const response = await getCartCheck({ product_id: product?.id });
    if (response?.success) {
      setCartStatus(true);
      setCartData(response?.cartItem);
      setChangeQuantity(!changeQuantity);
    } else {
      setCartStatus(false);
      setCartData(null);
    }
    // await getProduct();
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,

    reset,
    formState: { errors },
  } = useForm();

  const getProducts = async () => {
    const res = await getProductVariant(product?.id);
    if (res?.success) {
      setVariants(res?.data);
      if (!selectedVariant) {
        setSelectedVariant(res?.data[0]);
        // setSelected(res?.data[0]);
        setVariant(res?.data[0]);
      }
    }
  };

  const getCheckCart = async (product_id) => {
    const data = { product_id: product_id };
    const response = await getCartCheck(data);
    if (response?.success) {
      setCartStatus(true);
      setCartData(response?.cartItem);
    } else {
      setCartStatus(false);
      setCartData(null);
    }
    await getCartQuantity();
  };

  const reviews = product?.product_reviews || [];
  let averageRating = 0.0;

  if (reviews.length === 0) {
  } else {
    const totalRatings = reviews.reduce(
      (sum, review) => sum + parseFloat(review.ratings),
      0
    );
    averageRating = totalRatings / reviews.length;
    console.log("Average Rating:", averageRating.toFixed(2)); // toFixed(2) to format the average to 2 decimal places
  }

  // const handleVariantChange = async (variant) => {

  //   setSelectedVariant(variant);
  //   // setSelected(variant);
  //   setVariant(variant);

  // }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = { product_id: product?.id, variant_id: selectedVariant?.id };
  //       const response = await getCartCheck(data);
  //       if (response?.success) {
  //         setCartStatus(true);
  //         // setCartData(response?.cartItem);
  //       } else {
  //         setCartStatus(false);
  //         // setCartData(null);
  //       }
  //       await getCartQuantity();
  //     } catch (error) {
  //       console.error(error);
  //       // Handle error
  //     }
  //   };
  //   fetchData();

  // }, [selectedVariant]);

  // const [selectedVariant, setSelected] = useState([]);

  useEffect(() => {
    triggerCartUpdate();
  }, [cartStatus]);

  useEffect(() => {
    handleImageClick(IMG_URL + product?.image1);
    setPrdId(product?.id);
    getCheckCart(product?.id);
    getProducts(prdId);
  }, [product]);

  useEffect(() => {
    // getCheckCart(product?.id);
  }, [changeQuantity]);

  return (
    <section className="product-feature">
      <Row className="p-2">
        <div className="cctv-innerpage">
          <div className="container">
            <Row className="camera-sec">
              {/* <Col xxl={1} xl={1} lg={1} md={3} sm={2} xs={3} className="p-0">
                <div className="camera-slider">
                  {selectedVariant?.product_images.map((imageSrc, index) => (
                    <div
                      className="camera-imgs mt-2"
                      onClick={() =>
                        handleImageClick(IMG_URL + imageSrc.image)
                      }
                    >
                      <img
                        src={IMG_URL + imageSrc.image}
                        className="inner-img"
                      />
                    </div>
                  ))}
                </div>
              </Col> */}

              <Col xxl={4} xl={5} lg={5} md={9} sm={12} xs={12}>
                <div className="card-sec ">
                  <div class="wishlist-div">
                    {/* <FontAwesomeIcon
                      onClick={() => toggleHeartColor(0)}
                      style={{ color: isHeartRed[0] ? "red" : "#535353" }}
                      icon={faHeart}
                      className="heart-iconss"
                    /> */}
                    <Heart_Component
                      product={product}
                      // variant={selectedVariant}
                    />
                  </div>
                  <div className="product-detail text-center">
                    {selectedImage ? (
                      <img
                        className="product-img"
                        src={selectedImage}
                        alt="Selected"
                      />
                    ) : (
                      <img
                        className="product-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Homepage/products/marker-png.png"
                        }
                        alt="Default"
                      />
                    )}
                  </div>
                </div>
              </Col>

              <Col xxl={7} xl={6} lg={6} md={12} sm={12} xs={12}>
                <div className="product-information">
                  <div className="title-div">
                    <Row>
                      <Col xxl={12} lg={12} md={12}>
                        <h2>{product?.name}</h2>

                        <div className="d-flex pro-flex">
                          <div className="">
                            <h2>{selectedVariant?.v_name}</h2>
                          </div>
                          <div className="ps-2 pe-3">
                            <h6 className="star-h6">
                              <span className="review-span">
                                ({product?.product_reviews?.length} Reviews)
                              </span>
                            </h6>
                          </div>
                        </div>

                        <div className="d-flex pro-flex2">
                          <div className="">
                            {product?.sale?.sale_price ? (
                              <h2>₹ {product?.sale?.sale_price}</h2>
                            ) : (
                              <h2>₹ {product?.s_price}</h2>
                            )}
                            <p className="return-mrp ">
                              M.R.P.:{" "}
                              <span className="priceeee">{product?.mrp}</span>
                            </p>
                          </div>
                          <div className="ps-2 pe-2 bs-example ">
                            <Link
                              to="#"
                              className="d-inline-block pop-bg-set"
                              tabindex="0"
                              data-bs-toggle="popover"
                              data-bs-trigger="hover focus"
                              data-bs-content=" hh Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis exercitationem vitae reiciendis sequi ducimus expedita explicabo"
                              data-bs-placement="bottom"
                            >
                              <img
                                className="details-icon pop-img"
                                src="assets/icon/details.png"
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>

                        {/* <h2>{product?.name}</h2> */}
                      </Col>
                    </Row>
                    <p>
                      {htmlToReactParser.parse(
                        product?.product_detail?.about
                      ) || ""}

                      {/* </span> */}
                      {/* <button
                        onclick="myFunction()"
                        id="myBtn"
                        className="read-more"
                      >
                        Read more
                      </button> */}
                    </p>
                  </div>

                  <div className="button-effect mt-2">
                    {cartStatus ? (
                      <div className="blue-bg d-flex me-3">
                        <div className="circle-holder1">
                          <div className="d-flex add-cart-box mx-auto">
                            <button
                              variant="outline-secondary"
                              className="minus btn"
                              onClick={async () => {
                                await getCartQuantity(
                                  cartData?.id,
                                  "minus",
                                  cartData?.quantity
                                );
                              }}
                            >
                              -
                            </button>
                            <div className="quantity">{cartData?.quantity}</div>
                            <button
                              variant="outline-secondary"
                              className="add btn"
                              onClick={async () => {
                                await getCartQuantity(
                                  cartData?.id,
                                  "add",
                                  cartData?.quantity
                                );
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Common_Button
                        onClick={() => {
                          addCart({ product_id: product?.id });
                          triggerCartUpdate();
                        }}
                        className="add-to-cart-btn me-3"
                        icon={faCartShopping}
                        text="Add to Cart"
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default ProcductFeature;
