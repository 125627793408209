import React, { useEffect } from 'react'
import '../CheckoutProcess.css'
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'react-bootstrap';
const ThreePayment = ({ handlePrevious, handleNext, setPaymentMethod, paymentMethod }) => {
  const handleMethod = async (value) => {
    await setPaymentMethod(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
}, []);
  return (
    <>
      <section className='checkout-inner-steps'>
        <div className='payment-step'>
          <h1>Choose Payment Option</h1>
          <div className="radio-btn-input-holder">
            <Form.Check // prettier-ignore
              type="radio"
              name="method"
              label="Cash on delivery"
              value="cod"
              defaultChecked={paymentMethod === 'cod' || true}
              onClick={() => handleMethod('cod')}
            />

            <Form.Check // prettier-ignore
              type="radio"
              name="method"
              label="Online"
              value="online"
              defaultChecked={paymentMethod === 'online'}
              onClick={() => handleMethod('online')}
            />
          </div>
          {/* <Row>
            <Col xxl={3} xl={3} lg={3} md={6}>
              <div className='payment-card'>
                <p>Pay with credit card</p>
                <div className="mb-3  radio-btns ">
                  <Form.Check
                    type='radio'
                    name='addressOption'
                  />
                  <div className='img-div'>
                    <img
                      className="pay-imgs"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/checkoutstepform/visa.png"
                      }
                      alt="Banner"
                    />
                  </div>
                </div>
                <div className="mb-3  radio-btns">
                  <Form.Check
                    type='radio'
                    name='addressOption'
                  />
                  <div className='img-div'>
                    <img
                      className="pay-imgs"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/checkoutstepform/master.png"
                      }
                      alt="Banner"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={6}>
              <div className='payment-card'>
                <p>Pay with Paypal</p>
                <div className="mb-3  radio-btns ">
                  <Form.Check
                    type='radio'
                    name='addressOption'
                  />
                  <div className='img-div'>
                    <img
                      className="pay-imgs"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/checkoutstepform/paypal.png"
                      }
                      alt="Banner"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={6}>
              <div className='payment-card'>
                <p>Pay with UPI</p>
                <Row>
                  <Col xxl={6} xs={6}>
                    <div className="mb-3  radio-btns ">
                      <Form.Check
                        type='radio'
                        name='addressOption'
                      />
                      <div className='img-div'>
                        <img
                          className="pay-imgs"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/checkoutstepform/phone.png"
                          }
                          alt="Banner"
                        />
                      </div>
                    </div>
                    <div className="mb-3  radio-btns ">
                      <Form.Check
                        type='radio'
                        name='addressOption'
                      />
                      <div className='img-div'>
                        <img
                          className="pay-imgs"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/checkoutstepform/gpay.png"
                          }
                          alt="Banner"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xxl={6} xs={6}>
                    <div className="mb-3  radio-btns ">
                      <Form.Check
                        type='radio'
                        name='addressOption'
                      />
                      <div className='img-div'>
                        <img
                          className="pay-imgs"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/checkoutstepform/paytm.png"
                          }
                          alt="Banner"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row> */}
        </div>
        <div className="main-btn-div ">
          <button className="back-btn" onClick={handlePrevious}>Back</button>
          <button className="save-btn" onClick={handleNext}> Save & Next</button>
        </div>
      </section>
    </>
  )
}

export default ThreePayment