import React, { useState, useContext } from "react";
import "./ProdcutSlider.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

import SelectColor from "../../../AllModal/SelectColor";
import {
  getWishlist,
  addWishlist,
} from "../../../../utils/apis/Wishlist/Wishlist.js";
import { AddToCart } from "../../../../utils/apis/addToCart/addToCart.js";
import Heart_Component from "../../../Common-Component/Heart_Component/Heart_Component";
import SupermarketModal from "../../../Common-Component/Supermarket-modal/SupermarketModal";
import { Context } from "../../../../utils/context";
import { Link, useNavigate } from "react-router-dom";
const ProductSlider = ({ product, IMG_URL }) => {
  const [modalShow, setModalShow] = React.useState(false);

  const [isHeartRed, setIsHeartRed] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const navigate = useNavigate();

  const { triggerCartUpdate, setAddCartModal, addCartModal, signin } =
    useContext(Context);

  const toggleHeartColor = (index) => {
    const newHeartColors = [...isHeartRed];
    newHeartColors[index] = !newHeartColors[index];
    setIsHeartRed(newHeartColors);
  };

  const [number, setNumber] = useState(1);
  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  // const [showButtons, setShowButtons] = useState(false);
  // const [show, setShow] = useState(false);

  const calculateOffPercentage = (mrp, S_Price) => {
    if (mrp > 0) {
      return ((mrp - S_Price) / mrp) * 100;
    }
    return 0;
  };

  const addToWishlist = async (product_id) => {
    const res = await addWishlist(product_id);
    if (res?.success) {
      // getAllProduct();
    }
  };

  const addToCart = async (product_id) => {
    console.log("ppppp");
    const data = {
      product_id: product_id,
    };
    const res = await AddToCart(data);
    if (res?.success) {
      // getAllProduct();
    }
  };

  return (
    <div class="card-section">
      <div class="card">
        <div class="card-set">
          <div className="details-link">
            <Row>
              <Col xs={6}>
                <p className="offer-card-p">
                  {calculateOffPercentage(
                    product?.mrp,
                    product?.s_price
                  ).toFixed(2)}
                  %
                </p>
              </Col>
              <Col xs={6}>
                <div className="wishlist-div">
                  <Heart_Component product={product} />
                </div>
              </Col>
            </Row>
            <Link
              to={`/product-details/${product?.id}`}
              className="details-link"
            >
              <div className="card-body">
                <img
                  src={IMG_URL + product?.image1}
                  className="product-img"
                  alt="Product"
                />
              </div>
            </Link>
          </div>
        </div>
        <div class="details">
          <Row>
            <Col xxl={12} lg={12} md={12} xs={12}>
              <Link class="details-link">
                <p class="product-name">{product?.name}</p>
              </Link>
            </Col>
            <Col xxl={12} lg={12} md={12} xs={12}>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <p class="product-price">₹ {product?.s_price}</p>
                  <p className="product-price-disabled">
                    <span className="disablesub">₹ {product.mrp}</span>
                    <span className="offerprice">{product.Offerprice}</span>
                  </p>
                </div>
                <div id="first-div" className="shopping-img-align onclickcart">
                  <Button
                    variant="primary"
                    onClick={() => {
                      addToCart(product?.id);
                      setAddCartModal(true);
                      // triggerCartUpdate()
                    }}
                  >
                    {signin ? (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Icon/shopping-cart3.png"
                        }
                        className="shopping-cart-img"
                        alt="Shopping Cart"
                      />
                    ) : (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Icon/shopping-cart3.png"
                        }
                        className="shopping-cart-img"
                        alt="Shopping Cart"
                        // onClick={() => setShow(!show)}
                        // onClick={() => navigate("/login")}
                      />
                    )}
                  </Button>
                  {/* <SelectColor show={modalShow} onHide={() => setModalShow(false)} /> */}
                </div>
              </div>

              <br />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
  <SupermarketModal
    show={addCartModal}
    onHide={() => setAddCartModal(false)}
  />;
};

export default ProductSlider;
