import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./RelatedProdcut.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../utils/context";
import { getAllProducts } from "../../../utils/apis/Product/Product.js";
import {
  getWishlist,
  addWishlist,
} from "../../../utils/apis/Wishlist/Wishlist.js";
import { AddToCart } from "../../../utils/apis/addToCart/addToCart.js";
import Heart_Component from "../../Common-Component/Heart_Component/Heart_Component";
import SupermarketModal from "../../Common-Component/Supermarket-modal/SupermarketModal";
import { Pagination } from "swiper/modules";
const RelatedProduct = () => {
  const { id } = useParams();

  const [isHeartRed, setIsHeartRed] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleHeartColor = (index) => {
    const newHeartColors = [...isHeartRed];
    newHeartColors[index] = !newHeartColors[index];
    setIsHeartRed(newHeartColors);
  };

  const [number, setNumber] = useState(1);
  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  const [showButtons, setShowButtons] = useState(false);
  const [show, setShow] = useState(false);

  // Define an array of objects representing product data
  // const products = [
  //   {
  //     imgPath: "/assets/Image/Homepage/products/marker-png.png",
  //     percentage: "-15%",
  //     name: "Pen Name",
  //     price: "$5",
  //     selectcol: 0
  //   },
  //   {
  //     imgPath: "/assets/Image/Homepage/products/cello-tape.png",
  //     percentage: "-35%",
  //     name: "Pen Name",
  //     price: "$5",
  //     selectcol: 1
  //   },
  //   {
  //     imgPath: "/assets/Image/Homepage/products/sale-img.png",
  //     percentage: "-35%",
  //     name: "Pen Name",
  //     price: "$5",
  //     selectcol: 2
  //   },
  //   {
  //     imgPath: "/assets/Image/Homepage/products/review.png",
  //     percentage: "-35%",
  //     name: "Pen Name",
  //     price: "$5",
  //     selectcol: 3
  //   },
  //   {
  //     imgPath: "/assets/Image/Homepage/products/cello-tape.png",
  //     percentage: "-35%",
  //     name: "Pen Name",
  //     price: "$5",
  //     selectcol: 4
  //   },
  // ];

  const { IMG_URL, setAddCartModal, addCartModal } = useContext(Context);

  const [products, setProducts] = useState([]);

  const getAllProduct = async () => {
    const res = await getAllProducts();
    if (res?.success) {
      setProducts(res?.data);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  const addToWishlist = async (product_id) => {
    const res = await addWishlist(product_id);
    if (res?.success) {
      getAllProduct();
    }
  };

  const addToCart = async (product_id) => {
    const data = {
      product_id: product_id,
    };
    const res = await AddToCart(data);
    if (res?.success) {
      setShow(!show);
      getAllProduct();
    }
    setShow(!show);
  };

  const calculateOffPercentage = (mrp, S_Price) => {
    if (mrp > 0) {
      return ((mrp - S_Price) / mrp) * 100;
    }
    return 0;
  };

  const filteredProducts = products.filter(
    (product) => product.id !== parseInt(id)
  );

  return (
    <section className="related-prodcut mb-5">
      <div className="title-related-product">
        <h4 className="relate-prod">Related Products</h4>
      </div>
      <Container>
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          autoplay={{ delay: 300 }}
          className="mySwiper"
          breakpoints={{
            0: { slidesPerView: 1 },
            360: { slidesPerView: 1 },
            576: { slidesPerView: 2, spaceBetween: 10 },
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
            1800: { slidesPerView: 4 },
            2500: { slidesPerView: 4 },
          }}
        >
          {filteredProducts.map((product, index) => (
            <SwiperSlide key={index}>
              <div className="card-section">
                <div className="card">
                  <div className="card-set">
                    <Row>
                      <Col xs={6}>
                        <p className="offer-card-p">
                          {calculateOffPercentage(
                            product?.mrp,
                            product?.s_price
                          ).toFixed(2)}
                          %
                        </p>
                      </Col>
                      <Col xs={6}>
                        <div className="wishlist-div">
                          <Heart_Component
                            product={product}
                            // variant={selectedVariant}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="card-body">
                      <Link
                        to={`/product-details/${product?.id}`}
                        className="details-link"
                      >
                        <div className="product-img-div">
                          <img
                            src={IMG_URL + product?.image1}
                            className="product-img"
                            alt="..."
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="details">
                    <Row>
                      <Col xxl={12} lg={12} md={12} xs={12}>
                        <p className="product-name">{product.name}</p>
                      </Col>
                      <Col xxl={12} lg={12} md={12} xs={12}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <p className="product-price">{product.s_price}</p>
                          </div>
                          <div
                            id="first-div"
                            className="shopping-img-align onclickcart"
                          >
                            <Button
                              variant="primary"
                              className="carrrbtttt"
                              onClick={() => {
                                addToCart(product?.id);
                                setAddCartModal(true);
                                // triggerCartUpdate()
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Image/Icon/shopping-cart3.png"
                                }
                                className="shopping-cart-img"
                                alt="Shopping Cart"
                              />
                            </Button>
                            {/* <SelectColor show={modalShow} onHide={() => setModalShow(false)} /> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      <SupermarketModal
        show={addCartModal}
        onHide={() => setAddCartModal(false)}
      />
    </section>
  );
};

export default RelatedProduct;
